<template>
  <video class="img-fluid" autoplay playsinline muted>
    <source src="/video/ubity.mp4" />
    Ihr Browser kann dieses Video nicht wiedergeben.
  </video>

  <div class="text-center">

    <div class="row">
      <div class="col px-sm-5 mx-sm-5 mt-6">
        <h1>Seit 2023 <span class="mx-3">-</span> 60 Jahre Erfahrung</h1>

        <p class="px-sm-5">
          Passt nicht zusammen?
          Was ubity ausmacht, sind die Jahre der Erfahrung, die wir zu dritt inzwischen reichlich mitbringen.
          Uns eint das Streben nach noch eleganteren Lösungen, noch besserer Qualität, noch mehr Einfachheit
          und die Tatsache, dabei immer Spaß am Gerät zu haben.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col mt-6 px-sm-5">
        <paragraph imgSrc="img/vk.jpg">
          <paragraph-intro>
            <i class="bi bi-quote"></i>
          </paragraph-intro>
          In der Welt des Programmierens liegt die Magie für mich darin, ein Problem zu meistern und es durch Software immer wieder automatisch lösen zu lassen.
          <paragraph-outro>
            <span class="me-2">&mdash;</span> Volker Kindermann
          </paragraph-outro>
        </paragraph>
      </div>
    </div>


    <div class="row">
      <div class="col mt-6 px-sm-5">
        <paragraph imgSrc="img/ts.jpg">
          <paragraph-intro>
            <i class="bi bi-quote"></i>
          </paragraph-intro>
          Software-Entwicklung ist meine Berufung, komplexe Probleme einfach lösen ist meine Leidenschaft.
          Geistiger Stillstand geht einfach nicht.
          Das ist mein Antrieb, mich immer wieder mit neuen Technologien zu befassen,
          unkonventionelle Ideen zu verfolgen und neue Wege in der Entwicklung zu beschreiten.
          <paragraph-outro>
            <span class="me-2">&mdash;</span> Tobi Schmid
          </paragraph-outro>
        </paragraph>
      </div>
    </div>

    <div class="row d-none d-md-none">
      <div class="col offset-md-2">
        <ClientOnly>
          <apexchart type="radialBar" height="400" :options="options" :series="series" />
        </ClientOnly>
      </div>
    </div>


    <div class="row">
      <div class="col mt-6 px-sm-5">
        <paragraph imgSrc="img/lh.jpg">
          <paragraph-intro>
            <i class="bi bi-quote"></i>
          </paragraph-intro>
          Meine größte Freude liegt darin, Anwendungen so zu gestalten,
          dass Nutzer sie intuitiv bedienen &mdash; ohne es zu bemerken.
          Wenn UI/UX richtig gemacht ist, fällt es niemandem auf, aber es macht den Unterschied.
          <paragraph-outro>
            <span class="me-2">&mdash;</span> Lukas Hiereth
          </paragraph-outro>
        </paragraph>
      </div>
    </div>

  </div>
</template>

<style scoped>
  .mt-6 {
    margin-top: 7rem;
  }
</style>

<script setup>
  const options = {
    chart: {
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          }
        },
        barLabels: {
          enabled: true,
          useSeriesColors: true,
          offsetX: -8,
          fontSize: '16px',
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }
    ],
    labels: [
      "5 Jahre Embedded SW Architect (AUTOSAR)",
      "6 Jahre SW Architect Cloud Development",
      "2 Jahre Teamlead Infotainment Software",
      "3 Jahre Teamlead ADAS-Simulation-Tools",
      "4 Jahre Geschäftsführung CTO"
    ]
  };

  const series = [
    71,   // % from 7 years for 5 years
    86,   // % from 7 years for 6 years
    29,   // % from 7 years for 2 years
    43,   // % from 7 years for 3 years
    57    // % from 7 years for 4 years
  ];

</script>